import React from 'react';
import { bool, oneOfType, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
  JOB,
} from '../../util/types';
import { NamedLink } from '../../components';
import EditIcon from './EditIcon';

import css from './ListingPage.module.css';

export const ActionBarMaybe = props => {
  const { isOwnListing, listing, editParams, className ,isSharedBrief} = props;
  const state = listing.attributes.state;
  const typeListing = listing?.attributes?.publicData?.listingType;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isJobListing = typeListing === JOB;
  const pageName = isJobListing ? 'EditJobListingPage' : 'EditListingPage';

  if (isOwnListing || isSharedBrief) {
    let ownListingTextTranslationId;
    if (typeListing === 'client') ownListingTextTranslationId = 'ListingPage.ownBrief';
    else if (typeListing === 'partner') ownListingTextTranslationId = 'ListingPage.ownListing';
    else if (isJobListing) ownListingTextTranslationId = 'ListingPage.ownJobDescription';
    else if (isPendingApproval) ownListingTextTranslationId = 'ListingPage.ownListingPendingApproval';
    else if (isClosed) ownListingTextTranslationId = 'ListingPage.ownClosedListing';
    else if (isDraft){
      if(isJobListing) ownListingTextTranslationId = 'ListingPage.ownJobDescriptionDraft'
      else ownListingTextTranslationId = 'ListingPage.ownListingDraft';
    }

    const messageBrief = isDraft ? 'ListingPage.finishListing' : 'ListingPage.editListing';
    const messagePartner = isDraft ? 'ListingPage.finishListingPartner' : 'ListingPage.editListingPartner';
    const messageJob = isDraft ? 'ListingPage.finishJobListing' : 'ListingPage.editJobListing';

    const ownListingTextClasses = classNames(css.ownListingText, {
      [css.ownListingTextPendingApproval]: isPendingApproval,
    });

    const classes = classNames(css.actionBar, className);
    return (
      <div className={classes}>
        <p className={ownListingTextClasses}>
          <FormattedMessage id={ownListingTextTranslationId} />
        </p>
        <NamedLink className={css.editListingLink} name={pageName} params={editParams}>
          <EditIcon className={css.editIcon} />
          <span className={css.editText}>
            <FormattedMessage id={typeListing === "partner" ? messagePartner : isJobListing ? messageJob : messageBrief} />
          </span>
        </NamedLink>
      </div>
    );
  } else if (isClosed) {
    return (
      <div className={css.actionBar}>
        <p className={css.closedListingText}>
          <FormattedMessage id="ListingPage.closedListing" />
        </p>
      </div>
    );
  }
  return null;
};

ActionBarMaybe.propTypes = {
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: object.isRequired,
};

ActionBarMaybe.displayName = 'ActionBarMaybe';

export default ActionBarMaybe;
