import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration, { draftId, draftSlug } from '../../routeConfiguration';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
  USER_ROLE_CLIENT,
  USER_ROLE_PARTNER,
  LISTING_STATE_PUBLISHED,
  PUBLIC_BRIEF,
  PRIVATE_BRIEF,
  FULL_TIME_EMPLOYMENT,
  SECONDMENT,
  PROJECT_BASED,
  TRANSACTION_DETAILS_PAGE,
  HISTORY_MILESTONES_COMPLETED,
  JOB,
  USER_ROLE_CSM,
  FULLTIME_KEY,
  TRANSACTION_DETAIL_PAGE,
  MAX_BRIEF_LIMIT,
  RECRUITMENTS_UNDERWAY,
  PUBLIC_POSTED_JOB_APPLICATIONS,
  PRIVATELY_SHARED_JOB_APPLICATIONS,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  ModalProposal,
  ModalSendBrief,
  BriefLocation,
  IconCard,
  ReviewRating,
  Modal,
  Avatar,
  Reviews,
  ModalApplication,
  CsmAvatar,
  Button,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import {
  // sendEnquiry,
  setInitialValues,
  initBriefProposal,
  initProposal,
  getGoogleAccessToken,
  scheduleGoogleMeet,
  createZoomAuth, createZoomMeeting, retrieveZak, retrieveSignature, updateUserProfileThroughIntegrationSdk, initApplyJob,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import css from './ListingPage.module.css';
import SectionAdditionalMaybe from './SectionAdditionalMaybe';
import SectionCountryMaybe from './SectionCountryMaybe';
import SectionDocumentsMaybe from './SectionDocumentsMaybe';
import SectionOptionMaybe from './SectionOptionMaybe';
import SectionInfoExperienceWork from './SectionInfoExperienceWork';
import SectionInfoEducation from './SectionInfoEducation';
import SectionInfoCertification from './SectionInfoCertification';
import ActionBarMaybe from './ActionBarMaybe';
import { findOptionsForSelectFilter, industryType } from '../../marketplace-custom-config';
import { getUserRole, approvedUserStatus } from '../../util/userRole';
import SectionPackages from './SectionPackages';
import SectionLinkedFirm from '../../components/BecomeInsightGigPartnerPanel/SectionLinkedFirm';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { checkIfUserSubscribed, checkIsBrief, freePlan, getFirmData, getFirmId, getUserDetails, openAIImageDetails, sharedBriefIds } from '../../util/destructorHelpers';
import moment from 'moment';
import { DESCRIPTION, ENGAGEMENT, PHOTOS } from '../../components/EditListingWizard/EditListingWizardTab';
import ClientTags from './ClientTags';
import Recommendation from '../../components/Recommendation/Recommendation';
import SelectedExpertsTab from '../../components/SelectedExpertsTab/SelectedExpertsTab';
import { handleExpertDetails, updateFirmUserDetails } from '../../util/api';
import { ROLE } from '../../components/EditListingBriefWizard/EditListingBriefWizardTab';
import WorkRatingComponent from './WorkRatingComponent';
import { sortBy } from 'lodash';
import JobListing from './JobListing';
import classNames from 'classnames';
import { handleUpdateUserSubscriptionData } from '../OpenAIAppsPage/openAIAppsPageHelperFunction.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

//sorted milestones by date
export const sortedMilestones = milestone =>
  sortBy(milestone, m => {
    return m?.date ? m?.date : null;
  });

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;

    this._isMounted = false;

    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      openReviews: false,
      modalProposalOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isBrief: null,
      showBrief404: false,
      meetingErrorToaster: false,
      showReview: false,
      isOpen: false,
      profileViewsReachedModalState: false,
      modal: false,
      openJobModal: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleRequestModal = this.toggleRequestModal.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    this.setIsBrief();
    this._isMounted = true;
    this.setState({ meetingErrorToaster: false });
  }

  componentDidUpdate(prevProps) {
    const { isBrief } = this.state;
    const currentMonth = moment().month() + 1;;
    const { currentUser = {} } = this.props || {};

    if (isBrief === null) {
      this.setIsBrief();
    }
    const { meetingError, zoomMeetingError } = this.props;
    if (meetingError !== prevProps.meetingError || zoomMeetingError !== prevProps.zoomMeetingError) {
      this.setState({ meetingErrorToaster: true });
      this.timer = setTimeout(() => this.setState({ meetingErrorToaster: false }), 3000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
  }

  closeProfileViewsReachedModal = () => {
    const { history } = this.props;
    this.setState({ profileViewsReachedModalState: false }, () => {
      history.goBack();

    });
  };


  setIsBrief() {
    const { getListing, params, currentUser } = this.props;

    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    if (!listing || !listing.id || !currentUser) {
      return;
    }
    const isBrief = checkIsBrief(listing);

    let showBrief404 = false;
    if (isBrief) {
      const isOwnListing = listing?.author?.id?.uuid === currentUser?.id?.uuid;
      const isClient = getUserRole(currentUser) === USER_ROLE_CLIENT;
      showBrief404 = !isOwnListing && isClient;
    }

    this.setState({ isBrief, showBrief404 });
  }

  initProposal({ values, listing: briefListing }, listing, isUserSubscribed) {
    const { attachmentsUrls = [] } = briefListing && briefListing?.attributes.publicData;
    const { onInitProposal, currentUser, onUpdateProfile } = this.props;
    const listingId = listing.id.uuid;

    const { title, description, publicData, createdAt } = briefListing.attributes;
    const { maxPrice, minPrice, currencyFee, projectTimelines, domainExpertise, industrySector, isLocationSelected, locations } = publicData;
    const { briefId } = values;

    const params = {
      listingId,
      briefId,
      protectedData: {
        brief: {
          id: briefId,
          title,
          description,
          maxPrice,
          minPrice,
          createdAt: createdAt.getTime(),
          briefAttachmentsUrls: attachmentsUrls,
          briefSentAt: Date.now(),
          briefType: PRIVATE_BRIEF,
          currencyFee,
          projectTimelines,
          domainExpertise,
          industrySector,
          location: isLocationSelected ? locations?.map(e => e?.Location?.search) : null,
        },
      },
      currentAuthor: listing?.author,
      userEmail: getUserDetails(currentUser)?.email,
      userName: getUserDetails(currentUser)?.fullName,
      currentUser,
    };

    //Update client proposal received details to firm 
    function handleProjectReceived(txId) {
      const param = {
        type: 'projectReceived',
        clientId: currentUser?.id.uuid,
        expertId: listingId,
        projectId: txId
      }
      handleExpertDetails(param)
    }

    
    return onInitProposal(params).then(txId => {
      handleUpdateUserSubscriptionData({
        isUserSubscribed, 
        email: getUserDetails(currentUser)?.email, 
        type: 'sharedBriefs', 
        appName: title,
      })
      handleProjectReceived(txId?.uuid)
      return this.handleRedirectToOrder(txId);
    });
  }
  
  checkAvailablePartnerProfile(isBrief) {
    const {
      currentUserPartnerProfileInProgress,
      currentUserPartnerProfile,
      typePartner,
    } = this.props;

    const hasValidProfile =
      !currentUserPartnerProfileInProgress &&
      currentUserPartnerProfile?.attributes?.state === LISTING_STATE_PUBLISHED;

    if(typePartner !== USER_ROLE_PARTNER) return true;

    if (!hasValidProfile) {
      alert(`Please complete your profile to send ${isBrief ? 'proposals' : 'job applications'}.`);
      this.handleRedirectToEditPartnerProfilePage();
      return false;
    }
    else return true;
  }

  initBriefProposal(values, listing) {
    const { onInitBriefProposal, currentUser, currentUserListing, updateUserProfileThroughIsdk } = this.props;
    const currentAuthor = listing?.id && listing?.author;
    const ensuredAuthor = ensureUser(currentAuthor);

    const listingId = listing.id.uuid;
    const { title, description, publicData, createdAt } = listing.attributes;
    const { maxPrice, minPrice, currencyFee, projectTimelines, domainExpertise, industrySector, isLocationSelected, locations, proposalReceivedCount } = publicData;

    const {
      reason,
      scopeOfWork,
      methodology,
      // paymentFee: { amount, currency },
      amount,
      currency,
      paymentTerms,
      startDate,
      deadline,
      attachmentsUrls,
    } = values;
    const params = {
      userEmail: getUserDetails(currentUser)?.email,
      authorId: ensuredAuthor?.id?.uuid,
      userName: getUserDetails(currentUser)?.fullName,
      listingId,
      proposalReceivedCount,
      protectedData: {
        brief: {
          id: listingId,
          title,
          description,
          maxPrice,
          minPrice,
          createdAt: createdAt.getTime(),
          briefAttachmentsUrls: listing && listing?.attributes?.publicData.attachmentsUrls,
          briefType: PUBLIC_BRIEF,
          currencyFee,
          projectTimelines,
          domainExpertise,
          industrySector,
          location: isLocationSelected ? locations?.map(e => e?.Location?.search) : null
        },
      },
      metadata: {
        isTransactionRead: false,
        proposal: {
          reason,
          scopeOfWork,
          methodology,
          paymentFee: { amount : Number(amount.replace(/\,/g, ''))*100, currency },
          paymentTerms: Number(paymentTerms),
          startDate: +startDate.date,
          deadline: +deadline.date,
          attachmentsUrls,
          proposalSentAt: Date.now(),
        },
      },
    };
    function handleProposalSent(txId) {
      const param = {
        type: 'proposalSent',
        clientId: ensuredAuthor?.id.uuid,
        expertId: currentUserListing?.id.uuid,
        projectId: txId
      }
      handleExpertDetails(param)
    }

    // updateUserProfileThroughIsdk(profile);
    return onInitBriefProposal(params).then(txId => {
      handleProposalSent(txId?.uuid)
      return this.handleRedirectToOrder(txId);
    });
  }

  handleApplyJob(values, listing, pageName) {
    const { onInitApplyJob, currentUser, match } = this.props;
    const listingId = listing.id.uuid;
    const { title, description, publicData, createdAt } = listing.attributes;

    const params = {
      listingId,
      protectedData: {
        brief: {
          id: listingId,
          title,
          description,
          createdAt: createdAt.getTime(),
          briefType: "Public",
          ...publicData
        },
      },
      metadata: {
        isTransactionRead: false,
        proposal: {
          ...values,
          proposalSentAt: Date.now(),
        },
      },
      currentUser,
      currentAuthor: listing.author
    }

    return onInitApplyJob(params).then(async txId => {
      //update job application firm data
      updateFirmUserDetails({
        action: PUBLIC_POSTED_JOB_APPLICATIONS,
        userEmail: currentUser?.attributes?.email,
        listingId: listingId
      })
      //update ongoing recruitment firm data
      updateFirmUserDetails({
        action: RECRUITMENTS_UNDERWAY,
        userEmail: getUserDetails(listing.author)?.email,
        listingId: txId?.uuid
      })
      
      return this.handleRedirectToOrder(txId);
    })
  }

  handleRedirectToOrder(txId) {
    const { history } = this.props;
    history.push(
      createResourceLocatorString(
        TRANSACTION_DETAILS_PAGE,
        routeConfiguration(),
        { id: txId?.uuid, tab: TRANSACTION_DETAIL_PAGE },
        {}
      )
    );
  }

  handleRedirectToEditPartnerProfilePage() {
    const { history, currentUserListing } = this.props;
    const currentPathParams = {
      id: currentUserListing?.id?.uuid,
      slug: currentUserListing?.attributes?.title,
      type: LISTING_PAGE_PARAM_TYPE_DRAFT,
      tab: 'photos',
    };
    const draftPathParams = {
      id: draftId,
      slug: draftSlug,
      type: 'new',
      tab: 'description',
    };
    const params =
      currentUserListing && currentUserListing.id ? currentPathParams : draftPathParams;

    return history.push(
      createResourceLocatorString('EditListingPage', routeConfiguration(), params, {})
    );
  }

  handleSubmit(values, isUserSubscribed, pageName) {
    const { getListing, params, initProposalInProgress } = this.props;

    if (initProposalInProgress) {
      return;
    }

    const { isBrief } = this.state;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const isJobListing = listing?.attributes?.publicData?.listingType === JOB;

    if (!listing || !listing.id) {
      return;
    }

    if (isJobListing) this.handleApplyJob(values, listing, pageName)
    else if (isBrief) this.initBriefProposal(values, listing);
    else this.initProposal(values, listing, isUserSubscribed);
  }

  toggleRequestModal() {
    const { getListing, params } = this.props;
    const { isBrief } = this.state;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    
    if (isBrief === null || !listing || !listing.id) {
      return;
    }

    if (!this.checkAvailablePartnerProfile(isBrief)) {
      return;
    }

    this.setState({ modalProposalOpen: true });
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      typePartner,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onGetListListing,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      loadingListing,
      reviews,
      fetchReviewsError,
      // sendEnquiryInProgress,
      // sendEnquiryError,
      filterConfig,
      documentsProposal,
      initProposalInProgress,
      onGetGoogleAccessToken,
      onScheduleGoogleMeet,
      history,
      meetingInProgress,
      meetingCreated,
      transactions,
      meetingError,
      onCreateZoomAuth,
      onCreateZoomMeeting,
      onRetrieveZak,
      viewport,
      onRetrieveSignature,
      zoomMeetingInProgress,
      zoomMeetingError,
      zoomMeetingCreated,
      onUpdateProfile,
      selectedExpertIds,
      fetchTransactionInProgress,
      firmListing
    } = this.props;

    const { profileViewsReachedModalState = false } = this.state;
    const firmMetadata = !!firmListing?.id && getFirmData(firmListing)?.filter(firm => firm?.userId !== currentUser.id?.uuid);
    const isCsm = !!currentUser?.id && getUserRole(currentUser) === USER_ROLE_CSM;
    const linkedToFirms = currentUser?.id && currentUser?.attributes?.profile?.publicData?.linkedToFirms;
    const briefsAndJobCount = currentUser?.id && currentUser?.subscriptionData?.briefsAndJobCount;

    // Get the current month.
    const currentMonth = moment().month() + 1;
    const isFreePlanUsed = !!currentUser?.id && freePlan(currentUser);
    
    // const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    // const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isUserSubscribed = checkIfUserSubscribed(currentUser) || !!linkedToFirms?.length;
    const shouldRender = isUserSubscribed || isFreePlanUsed || linkedToFirms;
    const hasCurrentMonthLimitReached = isUserSubscribed && briefsAndJobCount >= MAX_BRIEF_LIMIT;

    const { isOpen, isBrief, showBrief404 } = this.state;

    const isApprovedUser = approvedUserStatus(currentUser);
    if (this._isMounted && !!currentUser && !isApprovedUser) {
      return <NamedRedirect name="LandingPage" />;
    }
    // const domainExpertise = findOptionsForSelectFilter('domainExpertise', filterConfig);
    // const industrySector = findOptionsForSelectFilter('industry', filterConfig);
    const industrySector = industryType;
    const countries = findOptionsForSelectFilter('country', filterConfig);

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const openAIImageURL = openAIImageDetails(currentListing)?.imageURL ?? null;
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };
    const listingType = isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;

    const { description = '', price = null, title = '', publicData } = currentListing.attributes;
    const listingRole = publicData && publicData.listingType;
    const isClient = getUserRole(currentUser) === USER_ROLE_CLIENT;
    const listingTab = (isClient || isCsm) ? listingRole === JOB ? ROLE : DESCRIPTION : ENGAGEMENT;
    const isApproved = currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;
    const pendingIsApproved = isPendingApprovalVariant && isApproved;
    const totalProjects = Array.isArray(transactions) && transactions.length;
    const isJobListing = listingRole === JOB;
    const firmId = !!currentUser?.id && (Array.isArray(linkedToFirms) && linkedToFirms[0]?.firmId || getFirmId(currentUser));

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    const sharedBriefs = currentUser?.id && sharedBriefIds(currentUser);
    const isSharedBrief = sharedBriefs?.length && sharedBriefs.some(id => id === rawParams?.id)

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing = userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer currentPage="ListingPage" />;

    if ((showListingError && showListingError.status === 404) || showBrief404) {
      // 404 listing not found
      // return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({ id: 'ListingPage.errorLoadingListingTitle' });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (loadingListing) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({ id: 'ListingPage.loadingListingTitle' });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const isCurrentAuthorCsm = getUserRole(ensuredAuthor) === USER_ROLE_CSM;
    const authorFirmName = getUserDetails(ensuredAuthor)?.firmName;
    const csmTooltipText = authorFirmName
      ? `This user is a Customer Success Manager working on behalf of ${authorFirmName} on the InsightGig platform.`
      : 'This user is a Customer Success Manager (CSM) on the InsightGig platform. CSMs work on behalf of their clients.';

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values, isUserSubscribed, 'ListingPage');
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const labelDescriptionlisting = <FormattedMessage id="ListingPage.labelDescriptionlisting" />;
    const labelWorkExperience = <FormattedMessage id="ListingPage.domainExpertise" />;
    const labelWorkExperienceListing = <FormattedMessage id="ListingPage.domainExpertiseListing" />;
    const labelIndustrySector = <FormattedMessage id="ListingPage.industrySector" />;
    const labelInsightTechniquesOrModels = <FormattedMessage id="ListingPage.insightTechniquesOrModels" />;
    const labelSoftwareKnowledge = <FormattedMessage id="ListingPage.labelSoftwareKnowledge" />;
    const labelSpokenLanguages = <FormattedMessage id="ListingPage.spokenLanguages" />;
    const labelEngagementRole = <FormattedMessage id="ListingPage.engagementRole" />;

    const actionBar = currentListing.id ? (
      <div onClick={e => e.stopPropagation()}>
        <ActionBarMaybe
          className={css.actionBarSingle}
          isOwnListing={isOwnListing}
          listing={currentListing}
          editParams={{
            id: listingId.uuid,
            slug: listingSlug,
            type: listingType,
            tab: listingTab,

          }}
          sharedBriefs={sharedBriefs}
          isSharedBrief={isSharedBrief}
        />
      </div>
    ) : null;

    const {
      industrySector: industrySectorOptions,
      insightTechniquesOrModels,
      softwareKnowledge,
      spokenLanguages,
      descriptionCategories: descriptionCategoriesOptions,
      optionaldescriptionCategories: optionaldescriptionCategoriesOptions,
      othersIndustries: descriptionOthersIndustries,
      othersDomain: descriptionOthersDomain,
      Experience: WorkExperienceOptions,
      Education: EducationOptions,
      Certification: CertificationOptions,
      country,
      createdAt,
      attachmentsUrls,
      servicePackage: packages,
      userLocation,
      locations: briefLocation,
      contractType,
      serviceUSP = {},
      industryUSP = {},
      domainExpertise,
      engagement = {},
    } = publicData || {};


    const createdAtString = moment(createdAt).format('Do MMMM YYYY');
    const sortedEngagementRoles = Object.keys(engagement)?.length &&
      ['projectBased', 'secondMent', 'fullTime'].map(role => Object.keys(engagement).find(item => item === role));

    const engagementRoles = Object.keys(engagement)?.length && sortedEngagementRoles
      ?.filter(key => engagement[key] !== false)
      ?.map(key => {
        const minPrice = typeof engagement[key]?.minPrice === 'string' 
          ? Number(engagement[key]?.minPrice?.replace(/\,/g, '')).toLocaleString("en-US") 
          : engagement[key]?.minPrice;
        const maxPrice = typeof engagement[key]?.maxPrice === 'string' 
          ? Number(engagement[key]?.maxPrice?.replace(/\,/g, '')).toLocaleString("en-US") 
          : engagement[key]?.maxPrice;

        return {
          key: key === 'fullTime' ? FULL_TIME_EMPLOYMENT : key === 'secondMent' ? SECONDMENT : PROJECT_BASED,
          value: engagement[key].currency &&
            `${engagement[key].currency} ${minPrice} - ${maxPrice} ${key === 'secondMent' ? 'per month' : 'per year'}`
        }
      });

    const isFullTimeJob = contractType === FULLTIME_KEY;
    const handleCreateZoomMeeting = async (params) => {
      const { isTokenExpired, timezone, duration, start_time, topic, agenda, userEmails, end_time } = params;

      const { privateData = {} } = currentUser?.id && currentUser?.attributes?.profile || {};
      const { zoomTokens = {} } = privateData;
      const email = zoomTokens && zoomTokens.email;
      const accessToken = zoomTokens && zoomTokens.access_token;
      const refreshToken = zoomTokens && zoomTokens.refresh_token;

      const meetingInfo = await onCreateZoomMeeting({
        email,
        accessToken,
        refreshToken,
        // txId: currentTransaction?.id.uuid,
        isTokenExpired,
        currentUserId: currentUser?.id,
        timezone,
        duration,
        start_time,
        topic,
        agenda,
        userEmails,
        end_time
      });

      return meetingInfo
    }

    let reviewsTotalRating = reviews && reviews.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.attributes?.rating;
    }, 0);
    const reviewsAverageRating = reviewsTotalRating && reviews ? reviewsTotalRating / reviews.length : 0;

    const averageCompletion = Array.isArray(transactions) && transactions.map(tx => {
      const history = tx?.history;
      const milestonesCompleted = history && Array.isArray(history) &&
        history.filter(ml => ml.attributes.content === HISTORY_MILESTONES_COMPLETED).map(item => item);

      const milestones = !!tx.milestones && sortedMilestones(tx.milestones);

      const lastMilestone = Array.isArray(milestones) && milestones?.length && milestones[milestones.length - 1];
      const lastCompletedMilestone = Array.isArray(milestonesCompleted) && milestonesCompleted.length &&
        milestonesCompleted[milestonesCompleted.length - 1];

      const compareMilestoneDates = !!lastCompletedMilestone &&
        moment(lastMilestone?.date).format('YYYY-MM-DD HH:mm:ss').valueOf() < moment(lastCompletedMilestone?.attributes?.createdAt).format('YYYY-MM-DD HH:mm:ss').valueOf();

      return !!compareMilestoneDates;
    });

    const projectCompletionRate =
      Array.isArray(averageCompletion) && averageCompletion.filter(Boolean).length
        ? 100 - (averageCompletion.filter(Boolean).length / totalProjects) * 100
        : !totalProjects ? 0 : 100;


    const ExpertBackgroundImage = () => {
      const hasImages = currentListing.images && currentListing.images.length > 0;
      const firstImage = hasImages ? currentListing.images[0] : null;
      const url = firstImage?.attributes?.variants['twitter']?.url

      return (
        <div className={css.expertBackgroundImage}>
          <img src={url} />
        </div>
      )
    }

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {actionBar}
            {isClient && selectedExpertIds?.length !== 0 && <SelectedExpertsTab history={history} />}
            <div className={css.blueBackground}>
              {/* {listingRole === USER_ROLE_PARTNER && <ExpertBackgroundImage />} */}
              <div className={css.contentContainer}>
                <div className={css.expertCover}>
                  {isCurrentAuthorCsm ? (
                    <div className={css.listingCsm} data-tooltip={csmTooltipText}>
                      <CsmAvatar user={currentAuthor} typeUser={typePartner} />
                    </div>
                  ) : (
                    <div className={css.coverLeft}>
                      <SectionAvatar user={currentAuthor} params={params} />
                    </div>
                  )}
                  <div className={css.coverRight}>
                    <SectionHeading
                      countries={countries}
                      reviews={reviews}
                      currentAuthor={currentAuthor}
                      authorDisplayName={authorDisplayName}
                      publicData={publicData}
                      onContactUser={this.onContactUser}
                      listing={currentListing}
                      userLocation={userLocation}
                      briefLocation={briefLocation}
                      currentUser={currentUser}
                      typePartner={typePartner}
                      isBrief={listingRole === USER_ROLE_CLIENT}
                      classNameBlock={css.engagementRoleElementBlock}
                      isOwnListing={isOwnListing}
                      isClient={isClient}
                      setModal={value => this.setState({ modal: value })}
                      onManageDisableScrolling={onManageDisableScrolling}
                      setOpenJobModal={(value) => this.setState({ openJobModal: value })}
                      toggleRequestModal={this.toggleRequestModal}
                      listingRole={listingRole}
                      engagementRoles={engagementRoles}
                      isJobListing={isJobListing}
                      hasCurrentMonthLimitReached={hasCurrentMonthLimitReached}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={classNames(css.mainWrapper, listingRole !== USER_ROLE_PARTNER && css.mainWrapperClient)}>
            </div> */}
            {listingRole === USER_ROLE_PARTNER ? (
              <div className={css.contentContainer}>
                <div className={css.bodyGrid}>
                  <div className={css.bodyLeft}>
                    <div className={css.shadowBox}>
                      <SectionDescriptionMaybe
                        description={description}
                        isBrief={listingRole !== USER_ROLE_PARTNER}
                        richTitle={richTitle}
                      />
                    </div>
                    {/* Display Engagement Roles */}
                    <div className={css.shadowBox}>
                      <div className={css.openTo}>
                        <h2 className={css.headingName}>{labelEngagementRole}</h2>
                        {!!engagementRoles?.length && engagementRoles.map(role => {
                          return (
                            <div className={css.openWrapper} key={role.key}>
                              <span className={css.semiBoldText}>{role.key}</span>
                              <span className={css.detailText}>{role.value && `: ${role.value}`}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className={css.shadowBox}>
                      <SectionOptionMaybe
                        optionaldescriptionCategoriesOptions={optionaldescriptionCategoriesOptions}
                        label={labelWorkExperience}
                        classNameBlock={css.domainExpertiseElementBlock}
                        classNameElement={css.domainExpertiseParentsElement}
                        classNameTitle={css.domainExpertiseElementTitle}
                        options={domainExpertise}
                        domainExpertise={findOptionsForSelectFilter('domainExpertise', filterConfig)}
                        serviceUSP={serviceUSP}
                      />
                    </div>
                    <div className={css.shadowBox}>
                      <SectionOptionMaybe
                        classNameBlock={css.industrySectorBlock}
                        descriptionCategoriesOptions={descriptionCategoriesOptions}
                        label={labelIndustrySector}
                        classNameElement={css.industrySectorParentsElement}
                        classNameTitle={css.industrySectorElementTitle}
                        options={industrySectorOptions}
                        industrySector={industrySector}
                        industryUSP={industryUSP}
                        isIndustrySector={true}
                      />
                    </div>
                    <div className={css.shadowBox}>
                      <SectionInfoExperienceWork
                        option={WorkExperienceOptions}
                      />
                    </div>
                    <div className={css.shadowBox}>
                      <SectionInfoEducation option={EducationOptions} />
                    </div>
                    {!!CertificationOptions?.length && (
                      <div className={css.shadowBox}>
                        <SectionInfoCertification option={CertificationOptions} />
                      </div>
                    )}
                    {!!packages?.length && (
                      <div className={css.shadowBox}>
                        <SectionPackages
                          packages={packages}
                          onManageDisableScrolling={onManageDisableScrolling}
                        />
                      </div>
                    )}
                    {reviews.length !== 0 && (
                      <div className={css.shadowBox}>
                        <h2 className={css.reviewsHeading}
                          onClick={() => this.setState({ showReview: !this.state.showReview })}>
                          <FormattedMessage id="ListingPage.reviewsHeading"
                            values={{ count: reviews.length ? reviews.length : 0 }} />
                          <span className={css.arrowButton}>
                            {!this.state.showReview ?
                              <IconCard brand="closearrow" /> :
                              <IconCard brand="openarrow" />
                            }
                          </span>
                        </h2>
                        {this.state.showReview && <Reviews className={css.reviewBox} reviews={reviews && reviews.length ? reviews : null} />}
                      </div>
                    )}
                    <ClientTags
                      listing={currentListing}
                      isClient={isClient}
                      currentUser={currentUser}
                      isFreePlanUsed={isFreePlanUsed}
                      history={history}
                    />
                  </div>
                  <div className={css.bodyRight}>
                    <BookingPanel
                      className={css.bookingPanel}
                      isBrief={isBrief}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      // onSubmit={handleBookingSubmit}
                      title={bookingTitle}
                      authorDisplayName={authorDisplayName}
                      onManageDisableScrolling={onManageDisableScrolling}
                      toggleRequestModal={this.toggleRequestModal}
                      editParams={{
                        id: listingId.uuid,
                        slug: listingSlug,
                        type: listingType,
                        tab: listingTab,
                      }}
                      reviews={reviews}
                      isClient={isClient}
                      documents={attachmentsUrls}
                      location={location}
                      onGetGoogleAccessToken={() => onGetGoogleAccessToken(params)}
                      onScheduleGoogleMeet={onScheduleGoogleMeet}
                      currentAuthor={ensuredAuthor}
                      currentUser={currentUser}
                      history={history}
                      params={params}
                      meetingInProgress={meetingInProgress}
                      meetingCreated={meetingCreated}
                      transactions={transactions}
                      meetingError={meetingError}
                      meetingErrorToaster={this.state.meetingErrorToaster}
                      onCreateZoomAuth={onCreateZoomAuth}
                      onRetrieveZak={onRetrieveZak}
                      handleCreateZoomMeeting={handleCreateZoomMeeting}
                      zoomMeetingInProgress={zoomMeetingInProgress}
                      zoomMeetingError={zoomMeetingError}
                      zoomMeetingCreated={zoomMeetingCreated}
                      onUpdateProfile={onUpdateProfile}
                      openReview={() => this.setState({ openReviews: true })}
                      totalProjects={totalProjects}
                      projectCompletionRate={projectCompletionRate}
                      isJobListing={isJobListing}
                      listingRole={listingRole}
                      isSharedBrief={isSharedBrief}
                      isCsm={isCsm}
                      firmId={firmId}
                      firmData={firmMetadata}
                      modal={this.state.modal}
                      setModal={value => this.setState({ modal: value })}
                      openJobModal={this.state.openJobModal}
                      setOpenJobModal={(value) => this.setState({ openJobModal: value })}
                      publicData={publicData}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.contentContainer}>
                <div className={css.bodyGrid}>
                  <div className={css.bodyLeft}>
                    {isJobListing ? (
                      <JobListing
                        listing={currentListing}
                        currentAuthor={currentAuthor}
                        intl={intl}
                      />
                    ) : (
                      <div className={css.mainContentBrief}>
                        <div className={css.shadowBox}>
                          <div className={css.sectionDescription}>
                            <h2 className={css.descriptionlabel}>
                              <FormattedMessage id="SectionHeading.projectObjective" />
                            </h2>
                            <p className={css.description}>{richTitle}</p>
                          </div>
                        </div>
                        <div className={css.shadowBox}>
                          <SectionDescriptionMaybe
                            description={description}
                            isBrief={listingRole !== USER_ROLE_PARTNER}
                          />
                        </div>
                        <div className={css.shadowBox}>
                          <SectionOptionMaybe
                            label={labelWorkExperienceListing}
                            classNameBlock={css.domainExpertiseElementBlock}
                            classNameElement={css.domainExpertiseParentsElement}
                            optionaldescriptionCategoriesOptions={descriptionOthersDomain}
                            classNameTitle={css.domainExpertiseElementTitle}
                            options={domainExpertise}
                            domainExpertise={findOptionsForSelectFilter('domainExpertise', filterConfig)}
                            listingRole={listingRole}
                            serviceUSP={serviceUSP}
                            descriptionOthersIndustries={descriptionOthersIndustries}
                          />
                        </div>
                        <div className={css.shadowBox}>
                          <SectionOptionMaybe
                            classNameBlock={css.industrySectorBlock}
                            descriptionCategoriesOptions={descriptionCategoriesOptions}
                            label={labelIndustrySector}
                            classNameElement={css.industrySectorParentsElement}
                            classNameTitle={css.industrySectorElementTitle}
                            options={industrySectorOptions}
                            industrySector={industrySector}
                            industryUSP={industryUSP}
                            isIndustrySector={true}
                          />
                        </div>
                        <div className={css.postedDateBox}>
                          <div className={css.postedlabelName}>Date posted:</div>
                          <div className={css.postedDate}>
                            <span className={css.createdAt}> {createdAtString}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={css.bodyRight}>
                    <BookingPanel
                      className={css.briefBookingPanel}
                      isBrief={isBrief}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      // onSubmit={handleBookingSubmit}
                      title={bookingTitle}
                      authorDisplayName={authorDisplayName}
                      onManageDisableScrolling={onManageDisableScrolling}
                      toggleRequestModal={this.toggleRequestModal}
                      editParams={{
                        id: listingId.uuid,
                        slug: listingSlug,
                        type: listingType,
                        tab: listingTab,
                      }}
                      publicData={publicData}
                      reviews={reviews}
                      isClient={isClient}
                      documents={attachmentsUrls}
                      location={location}
                      onGetGoogleAccessToken={() => onGetGoogleAccessToken(params)}
                      onScheduleGoogleMeet={onScheduleGoogleMeet}
                      currentAuthor={ensuredAuthor}
                      currentUser={currentUser}
                      history={history}
                      params={params}
                      meetingInProgress={meetingInProgress}
                      meetingCreated={meetingCreated}
                      transactions={transactions}
                      meetingError={meetingError}
                      meetingErrorToaster={this.state.meetingErrorToaster}
                      onCreateZoomAuth={onCreateZoomAuth}
                      onCreateZoomMeeting={onCreateZoomMeeting}
                      onRetrieveZak={onRetrieveZak}
                      handleCreateZoomMeeting={handleCreateZoomMeeting}
                      zoomMeetingInProgress={zoomMeetingInProgress}
                      zoomMeetingError={zoomMeetingError}
                      zoomMeetingCreated={zoomMeetingCreated}
                      onUpdateProfile={onUpdateProfile}
                      isSharedBrief={isSharedBrief}
                      isJobListing={isJobListing}
                      listingRole={listingRole}
                      isCsm={isCsm}
                      firmId={firmId}
                      firmData={firmMetadata}
                      modal={this.state.modal}
                      setModal={value => this.setState({ modal: value })}
                    />
                  </div>
                </div>
              </div>
            )}

            <Modal
              id="reviewModal"
              isOpen={this.state.openReviews}
              onClose={() => this.setState({ openReviews: false })}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.reviewModal}
            >
              <div className={css.reviewBoxWrapper}>
                <div className={css.heading}>
                  <FormattedMessage id="ListingPage.reviewsPartnersHeading" values={{ count: reviews?.length }} />
                </div>
                {reviews && reviews.map(review => {
                  const content = review && review?.attributes?.content;
                  const rating = review && review?.attributes?.rating;
                  const authorName = review && review?.author?.attributes?.profile?.displayName;
                  const createdDate = review && review?.attributes?.createdAt;
                  return (
                    <div className={css.reviewBody} key={createdDate}>
                      <Avatar className={css.avatar} user={review?.author} disableProfileLink />
                      <div>
                        <h4 className={css.reviewMessage}>{content}</h4>
                        <div className={css.reviewDateRate}>
                          <span className={css.dateText}>{authorName}  •  {moment(createdDate).format('MMMM yyyy')}  •  </span>
                          <span className={css.ratingStar}>
                            <ReviewRating
                              rating={rating}
                              stars={[1, 2, 3, 4, 5]}
                              className={css.desktopReviewRating}
                              reviewStarClassName={css.reviewRatingStar}
                            />
                          </span>
                        </div>
                      </div>
                    </div>)
                })}
                <div className={css.reviewBody}>
                </div>

              </div>
            </Modal>

            {isBrief ? (
              <ModalProposal
                id="ModalProposal.ListingPage"
                isOpen={this.state.modalProposalOpen}
                onClose={() => this.setState({ modalProposalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                onSubmit={handleBookingSubmit}
                inProgress={initProposalInProgress}
                listingId={listingId.uuid}
                documents={documentsProposal}
              />
            ) : isJobListing ? (
              <ModalApplication
                id="ModalApplication"
                isOpen={this.state.modalProposalOpen}
                onClose={() => this.setState({ modalProposalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                onSubmit={handleBookingSubmit}
                inProgress={initProposalInProgress}
                isFullTimeJob={isFullTimeJob}
              />
            ) : (
              <ModalSendBrief
                id="ModalSendBrief"
                isOpen={this.state.modalProposalOpen}
                onClose={() => this.setState({ modalProposalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                onSubmit={handleBookingSubmit}
                inProgress={initProposalInProgress}
                currentUser={currentUser}
              />
            )}
            <Modal
              id="profileViewsReachedModal"
              isOpen={isClient && !isUserSubscribed}
              onClose={this.closeProfileViewsReachedModal}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.reviewModal}
              isProfileViewsModal={true}
            >
              <div className={css.profileViewsReachedModalWraper}>
                <h2 className={css.profileViewsReachedModalHeading}>
                  <FormattedMessage id="ListingPage.profileViewLimitReachedTitle" />
                </h2>
                <p className={css.profileViewsReachedModalDescription}>
                  <FormattedMessage id="ListingPage.profileViewLimitReachedDescription" />
                  <NamedLink name="StripeSubscriptionPage" className={css.hereLink}>
                    <FormattedMessage id="ListingPage.profileViewLimitReachedLinkText" />
                  </NamedLink>
                </p>

                <div className={css.buttonsContainer}>
                  <Button type='button' onClick={this.closeProfileViewsReachedModal} className={css.cancelButton}>
                    <FormattedMessage id="ListingPage.profileViewLimitReachedCancelButton" />
                  </Button>
                </div>
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page >
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  // sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  // sendEnquiryInProgress: bool.isRequired,
  // sendEnquiryError: propTypes.error,
  // onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    loadingListing,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    // sendEnquiryInProgress,
    // sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    documents,
    documentsProposal,
    initProposalInProgress,
    meetingInProgress,
    meetingCreated,
    transactions,
    meetingError,
    zoomMeetingInProgress,
    zoomMeetingError,
    zoomMeetingCreated,
    firmListing,
    fetchTransactionInProgress
  } = state.ListingPage;
  const {
    currentUser,
    typePartner,
    currentUserPartnerProfileInProgress,
    currentUserPartnerProfile,
    currentUserListing,
  } = state.user;
  const { selectedExpertIds } = state.ComparisonPage;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  return {
    isAuthenticated,
    currentUser,
    typePartner,
    getListing,
    getOwnListing,
    currentUserListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    loadingListing,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    // sendEnquiryInProgress,
    // sendEnquiryError,
    documents,
    documentsProposal,
    initProposalInProgress,
    currentUserPartnerProfileInProgress,
    currentUserPartnerProfile,
    meetingInProgress,
    meetingCreated,
    transactions,
    meetingError,
    zoomMeetingInProgress,
    zoomMeetingError,
    zoomMeetingCreated,
    selectedExpertIds,
    firmListing
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  // onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onInitBriefProposal: params => dispatch(initBriefProposal(params)),
  onInitProposal: params => dispatch(initProposal(params)),
  onGetGoogleAccessToken: params => dispatch(getGoogleAccessToken(params)),
  onScheduleGoogleMeet: params => dispatch(scheduleGoogleMeet(params)),
  onCreateZoomAuth: params => dispatch(createZoomAuth(params)),
  onCreateZoomMeeting: params => dispatch(createZoomMeeting(params)),
  // onRetrieveZak: params => dispatch(retrieveZak(params)),
  // onRetrieveSignature: params => dispatch(retrieveSignature(params)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  updateUserProfileThroughIsdk: data => dispatch(updateUserProfileThroughIntegrationSdk(data)),
  onInitApplyJob: (params) => dispatch(initApplyJob(params))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
