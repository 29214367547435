import classNames from 'classnames';
import { NamedLink } from '../../components';
import css from './ListingPage.module.css'
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import { FULL_TIME_EMPLOYMENT, SECONDMENT, SECONDMENT_KEY } from '../../util/types';
import { getUserDetails } from '../../util/destructorHelpers';
const { Money } = sdkTypes;

const JobListing = (props) => {
  const { listing, currentAuthor, intl } = props;
  const { title, description, publicData } = listing.attributes;
  const typeCompany = currentAuthor?.id && currentAuthor?.attributes?.profile?.publicData?.typeCompany;
  const {
    aboutCompany, jobLocation, seniority, responsibility, skill, experience, industrySector = [], ctc, attachmentFile,
    employmentLength, contractType, isRemoteLocation
  } = publicData;
  const { currency, maxPrice, minPrice } = ctc || {};
  const employement = employmentLength === 'Not applicable' ? employmentLength : `${employmentLength} months`;
  const employementType = contractType === SECONDMENT_KEY ? SECONDMENT : FULL_TIME_EMPLOYMENT;

  const firmId = currentAuthor?.id && getUserDetails(currentAuthor)?.firmId;
  const firmTitle = currentAuthor?.id && getUserDetails(currentAuthor)?.firmName;

  const minMoney = minPrice && new Money(Number(minPrice.replace(/[^a-z0-9-]/g, '')) * 100, currency)
  const maxMoney = maxPrice && new Money(Number(maxPrice.replace(/[^a-z0-9-]/g, '')) * 100, currency)

  return (
    <div className={css.mainContentBrief}>
      <div className={css.companyDetails}>
        {(firmTitle && firmId) && (
          <>
            <NamedLink
              className={css.briefFirmTitleLink}
              name="EditBecomeInsightGigPartnerPageDetails"
              params={{ id: firmId, slug: firmTitle }}
            >
              <span>{firmTitle}</span>
            </NamedLink>
            <span className={css.pointDelimiter}> • </span>
          </>
        )}
        {typeCompany && <span>{typeCompany}</span>}
      </div>
      <div className={css.jobListingtTitle}>{title}</div>
      <div className={classNames(css.descriptionRow, css.inline)}>
        <h3><FormattedMessage id='JobListing.employementType' /></h3>
        <div className={css.employementText}>{employementType}</div>
      </div>
      <div className={classNames(css.descriptionRow, css.inline)}>
        <h3><FormattedMessage id='JobListing.location' /></h3>
        <p>{isRemoteLocation ? 'This is a remote job.' : jobLocation?.search}</p>
      </div>
      <div className={classNames(css.descriptionRow, css.inline)}>
        <h3><FormattedMessage id='JobListing.seniority' /></h3>
        <p style={{ textTransform: 'capitalize' }}>{seniority}</p>
      </div>
      <div className={css.descriptionRow}>
        <h3><FormattedMessage id='JobListing.jobDescription' /></h3>
        <p>{description}</p>
      </div>
      <div className={css.descriptionRow}>
        <h3><FormattedMessage id='JobListing.responsibility' /></h3>
        <p>{responsibility}</p>
      </div>
      <div className={css.descriptionRow}>
        <h3><FormattedMessage id='JobListing.skillsRequired' /></h3>
        <div className={css.inputSelectCard}>
          {Array.isArray(skill) && skill.map(item =>
            <span className={css.servicesSelected} key={item}>{item}</span>
          )}
        </div>
      </div>
      <div className={classNames(css.descriptionRow, css.inline)}>
        <h3><FormattedMessage id='JobListing.experience' /></h3>
        <p>{!!experience ? `${experience} years` : 'N/A'}</p>
      </div>
      <div className={classNames(css.descriptionRow, css.inline)}>
        <h3><FormattedMessage id='JobListing.employmentLength' /></h3>
        <p>{employement}</p>
      </div>
      <div className={classNames(css.descriptionRow, css.inline)}>
        <h3><FormattedMessage id='JobListing.ctcBudget' /></h3>
        <p>{ctc && formatMoney(intl, minMoney)} - {ctc && formatMoney(intl, maxMoney)}</p>
      </div>
      <div className={css.descriptionRow}>
        <h3><FormattedMessage id='JobListing.aboutCompany' /></h3>
        <p>{aboutCompany}</p>
      </div>
      <div className={css.descriptionRow}>
        <h3><FormattedMessage id='JobListing.industrySector' /></h3>
        {industrySector?.length !== 0 && industrySector?.map(service => (
          <label className={css.industryLabel} key={service}>{service}</label>
        ))}
      </div>
      {attachmentFile && (
        <div>
          <h3 className={css.fieldHeader}><FormattedMessage id='JobListing.attachment' /></h3>
          <FileView file={attachmentFile} />
        </div>
      )}
    </div>
  )
}

export default JobListing