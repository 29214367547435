import { FormattedMessage, injectIntl, } from "react-intl"
import { compose } from "redux"
import css from './SelectedExpertsTab.module.css'
import IconCard from "../IconCard/IconCard"
import { useDispatch, useSelector } from "react-redux"
import { AvatarMedium } from "../Avatar/Avatar"
import { BorderButton, PrimaryButton } from "../Button/Button"
import { selectExpert } from "../../containers/ComparisonPage/ComparisonPage.duck"
import { createResourceLocatorString } from "../../util/routes"
import routeConfiguration from "../../routeConfiguration"
export const maxExpertSelectionCount = 4;

const SelectedExpertsTab = (props) => {
  const { history } = props;
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  const { selectedExpertIds, selectedExpertListings } = useSelector(state => state.ComparisonPage)
  const isMaxExpertSelected = selectedExpertIds?.length === maxExpertSelectionCount;

  const removeExpert = (listingId) => {
    const ids = selectedExpertIds.filter(id => id !== listingId)
    const listings = selectedExpertListings.filter(ls => ls?.id.uuid !== listingId)

    const param = { ids, listings }
    dispatch(selectExpert(param))
  }

  function handleClose(){
    const param = { ids: [], listings: [] }
    dispatch(selectExpert(param))
  }

  return (
    <div className={css.tabContainer}>
      <div className={css.header}>
        <div className={css.titleContainer}>
          <h2><FormattedMessage id="SelectedExpertsTab.title" /></h2>
          <p><FormattedMessage id="SelectedExpertsTab.description" /></p>
        </div>
        <div className={css.closeButton} onClick={handleClose}>
          <span><FormattedMessage id="SelectedExpertsTab.close" /></span>
          <IconCard brand="crossSign" />
        </div>
      </div>

      <div className={css.wrapperContainer}>
        <div className={css.experts}>
          {selectedExpertListings?.length !== 0 && selectedExpertListings?.map(listing => {
            const listingId = listing?.id?.uuid;
            const author = listing?.author;
            const expertName = author?.attributes?.profile?.publicData?.fullName;
            return (
              <div className={css.expertListing}>
                <AvatarMedium user={author} />
                <h3>{expertName}</h3>
                <span onClick={() => removeExpert(listingId)} className={css.crossSign}>
                  <IconCard brand="crossSign" />
                </span>
              </div>
            )
          })}
        </div>
        <div className={css.bottomButton}>
          {!isMaxExpertSelected &&
            <BorderButton
              className={css.addExpertButton}
              onClick={() => history.push('/s')}
            >
              <FormattedMessage id="SelectedExpertsTab.addExpertButton" />
            </BorderButton>
          }


          <PrimaryButton
            className={css.compareButton}
            onClick={() => {
              history.push(createResourceLocatorString('ComparisonPage',
                routeConfiguration(), {
                id: selectedExpertIds?.join('_')
              }));
            }}
          >
            <FormattedMessage id="SelectedExpertsTab.compareButton" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default compose(injectIntl)(SelectedExpertsTab)