import React, { useState } from 'react';
import { IconCard, Modal, NamedLink } from '../../components';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';

const SectionPackages = props => {
  const [moreOptionModal, setMoreOptionModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [show, setShow] = useState(false);

  const { packages, onManageDisableScrolling } = props;
  if (!packages) {
    return null;
  }

  const packagesOptions = config.custom.packagesOptions;
  const filterPackages =
    packagesOptions &&
    packagesOptions.length &&
    packagesOptions.filter(p => packages.includes(p.key));
  const listingPackages = config.custom.listingPackages;

  const getParentLabel = key => {
    const parent = listingPackages && listingPackages.find(item => item.key === key);
    return parent && parent.label;
  };
  const handleModalData = (item) => {
    setMoreOptionModal(true)
    setModalData(item)
  }
  return (
    <div className={css.sectionFeatures}>
      <h2
        className={css.sectionHeading}
        onClick={() => setShow(!show)}
      >
        <FormattedMessage id="SectionPackages.heading" />
        <span className={css.arrowButton}>
          {show ?
            <IconCard brand="openarrow" /> :
            <IconCard brand="closearrow" />
          }
        </span>
      </h2>
      {show && 
        <div className={css.packagesCard}>
          {filterPackages?.length !==0 && filterPackages.map(p => (
            <div key={p.key} className={css.packageContainer}>
              <div className={css.centerBox}>
                <p className={css.packageTitle}>{p.label}</p>
                <p className={css.parentLabel}>{getParentLabel(p.parentKey)}</p>
              </div>
              <p className={css.packageDescription}>{p.description}</p>
              <p className={css.moreOption} onClick={() => handleModalData(p)}>
                {p.moreOption}
              </p>
            </div>
          ))}
        </div>
      }
      <Modal
        id="SectionPackages.moreOptionModal"
        contentClassName={css.enquiryModalContent}
        isOpen={moreOptionModal}
        onClose={() => setMoreOptionModal(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.packageContainerModal}>
          <div className={css.centerBox}>
            <p className={css.packageTitle}>{modalData?.label}</p>
            <p className={css.packageLabel}>{getParentLabel(modalData?.parentKey)}</p>
          </div>
          <p className={css.packageDescription}>{modalData?.description}</p>
        </div>
      </Modal>
    </div>
  );
};

export default SectionPackages;
