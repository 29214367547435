import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, IconCard, InlineTextButton, Modal, NamedLink, OutsideClickHandler, PrimaryButton, ReviewRating } from '../../components';
import moment from 'moment';

import css from './ListingPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getFirmListing } from '../SearchPage/SearchPage.duck';
import { draftId } from '../../routeConfiguration';
import { USER_ROLE_PARTNER } from '../../util/types';
import { generateResume, sendEmailUsingZepto, updateListingPublicData } from '../../util/api';
import config from '../../config';
import { checkIsBrief, getPreferredInsightGigPartner, getTotalWorkYears, getUserDetails } from '../../util/destructorHelpers';
import { selectExpert } from '../ComparisonPage/ComparisonPage.duck';
import { maxExpertSelectionCount } from '../../components/SelectedExpertsTab/SelectedExpertsTab';
import classNames from 'classnames';
import { countryCodes } from '../../translations/countryCodes';
import { saveAs } from 'file-saver';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const SectionHeading = props => {
  const {
    currentAuthor,
    authorDisplayName,
    onContactUser,
    publicData,
    reviews,
    createdAt,
    countries,
    listing,
    userLocation,
    briefLocation,
    currentUser,
    onManageDisableScrolling,
    isClient,
    setModal,
    setOpenJobModal,
    toggleRequestModal,
    isBrief,
    isOwnListing,
    listingRole,
    isJobListing,
    hasCurrentMonthLimitReached,
  } = props;

  const dispatch = useDispatch();
  const [openMaxExpertModal, setOpenMaxExpertModal] = useState(false);
  const [openBriefLimitModal, setOpenBriefLimitModal] = useState(false);
  const [fetchResume, setFetchResume] = useState(false);
  const { selectedExpertIds, selectedExpertListings } = useSelector(state => state.ComparisonPage);
  const preferredInsightGigPartner = getPreferredInsightGigPartner(currentUser);
  const [favouriteExperts, setFavouritExperts] = useState(preferredInsightGigPartner || []);

  const listingId = listing.id && listing.id.uuid;
  const {engagement = {}, Experience=[], engagementRoles, firmLogo } = !!listing?.id ? publicData : {};
  const firmLogoUrl = firmLogo?.url
  const firmLogoShape = firmLogo?.shape
  const isUserAcceptJob = !!engagementRoles?.find(role => role === 'secondment' || role === 'fullTimeEmployment');
  const totalExperience = getTotalWorkYears(Experience);

  const {typeCompany, location, companyName} = currentAuthor?.attributes?.profile?.publicData || {};
  const clientAddress = location?.selectedPlace?.address;
  const { selectedPlace } = userLocation || {};
  const { address } = selectedPlace || {address: clientAddress};

  const firmId = getUserDetails(currentAuthor)?.firmId;
  const firmTitle = getUserDetails(currentAuthor)?.firmName;

  const currentUserName = getUserDetails(currentUser)?.firstName;
  const currentUserEmail = getUserDetails(currentUser)?.email;

  const handleSelectExpert = () => {
    if (selectedExpertIds?.length === maxExpertSelectionCount) {
      setOpenMaxExpertModal(true)
    } else {
      const param = {
        ids: [...selectedExpertIds, listingId],
        listings: [...selectedExpertListings, listing]
      }
      dispatch(selectExpert(param));
    }
  }
  const isExpertSelected = selectedExpertIds?.length ? selectedExpertIds?.includes(listingId) : false;
  const userCountry = address?.split(',').at(-1).trim();
  const countryCode = countryCodes?.find(code => (code.en === userCountry) || (code.de === userCountry))?.code;

  async function handleGeneratePdf(){
    setFetchResume(true)
    const response = await generateResume({ listingId: listingId})

    if (response?.data?.data) {
      const buffer = Buffer.from(response.data.data, 'base64')
      // Create a Blob object from the Buffer data
      const blob = new Blob([buffer]);
  
      // Downloading the file
      saveAs(blob, `${authorDisplayName}_resume.pdf`);
    }
    setFetchResume(false)
  }

  const isProjectBased = engagement?.projectBased;
  const showTooltip = listingRole === USER_ROLE_PARTNER && !isProjectBased;
  const proejctBasedTooltip = 'The Expert is not open to taking up ad hoc projects. ';
  const jobBasedTooltip = 'The Expert is not open to Secondment or Employment opportunities.';

  const handleOpenSendBrief = () => {
    if(hasCurrentMonthLimitReached) setOpenBriefLimitModal(true)
    else if(!showTooltip) toggleRequestModal()
  }

  const handleSendJobDescription = () => {
    if(hasCurrentMonthLimitReached) setOpenBriefLimitModal(true)
    else if(isUserAcceptJob) setOpenJobModal(true)
  }

  const handleSendAdminEmail = async () => {
    const emailParams = {
      step: 'maxBriefLimit',
      userName: currentUserName,
      userEmail: currentUserEmail,
    }
		const response = await sendEmailUsingZepto(emailParams)
		if (response.message === 'Success') {
			setOpenBriefLimitModal(false)
		}
	}

  const handlePreferredInsightGigPartner = () => {
    let modifiedPreferredPartner = []

    if (favouriteExperts?.includes(listingId)) {
      modifiedPreferredPartner = favouriteExperts.filter(id => id !== listingId);
    }
    else {
      modifiedPreferredPartner = [...favouriteExperts, listingId]
    }

    const profile = {
      protectedData: {
        preferredInsightGigPartner: modifiedPreferredPartner
      },
    };
    setFavouritExperts(modifiedPreferredPartner)
    dispatch(updateProfile(profile));
  }
  const displayName = getUserDetails(currentAuthor).fullName

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <div className={css.userInfoBlock}>
          <div className={css.userName}>
            <NamedLink
              name="ProfilePage"
              params={{ id: currentAuthor?.id.uuid ? currentAuthor.id.uuid : draftId }}
            >
              {displayName}
            </NamedLink>
          </div>
          {address && (
            <div className={css.briefLocation}>
              <div className={css.locationBox}>
              <img
                alt="United States"
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
              />
                <span>{address}</span>
              </div>
            </div>
          )}
          <div className={css.subHeadingBox}>
            {(firmTitle && firmId) ? (
              <NamedLink
                className={css.firmTitleLink}
                name="EditBecomeInsightGigPartnerPageDetails"
                params={{ id: firmId, slug: firmTitle }}
              >
                <span>{firmTitle}</span>
              </NamedLink>
            ) : (
              <span className={css.subhHeadingtext}>{companyName}</span>
            )}
            {typeCompany && (
              <>
                <span className={css.pointDelimiter}> • </span>
                <span className={css.subhHeadingtext}>{typeCompany}</span>
              </>
            )}
            {(isBrief || isJobListing) ? null : totalExperience === 0 ? (
              <>
                <span className={css.pointDelimiter}> • </span>
                <span className={css.subhHeadingtext}>
                  <FormattedMessage id="ListingPage.noWorkExperienceUser" />
                </span>
              </>
            ) : totalExperience >= 1 ? (
              <>
                <span className={css.pointDelimiter}> • </span>
                <span className={css.subhHeadingtext}>
                  {totalExperience}
                  <FormattedMessage
                    id="ListingPage.experienceUser"
                    values={{ values: totalExperience > 1 ? 's' : null }}
                  />
                </span>
              </>
            ) : (
              <>
                <span className={css.pointDelimiter}> • </span>
                <span className={css.subhHeadingtext}>
                  <FormattedMessage id="ListingPage.lessThanOneYearExperienceUser" />
                </span>
              </>
            )}
          </div>
          <div className={classNames(css.bottomButton, !(isBrief || isJobListing) && css.bottomButtonExpert)}>
            {isClient && (
              <>
                {(isBrief || isJobListing) ? null : (
                  <>
                    <div className={classNames(css.sendBriefButtonWrapper)}>
                      <button 
                        className={classNames(css.scheduleButton, showTooltip && css.tooltip)} 
                        onClick={handleOpenSendBrief} 
                        data-tooltip={proejctBasedTooltip}
                      >
                        <FormattedMessage id="BookingPanel.sendBrief" />
                      </button>
                      <button 
                        className={classNames(css.scheduleButton, !isUserAcceptJob && css.tooltip)} 
                        onClick={handleSendJobDescription} 
                        data-tooltip={jobBasedTooltip}
                      >
                        <FormattedMessage id="BookingPanel.sendJobDescription" />
                      </button>
                      <button 
                        className={css.scheduleButton} 
                        onClick={() => {
                          setModal(true)
                          sessionStorage.removeItem('params')
                        }} 
                      >
                        <FormattedMessage id="BookingPanel.ScheduleMeeting" />
                      </button>
                    </div>
                    <div className={css.favourites}>
                      <span 
                        className={classNames(css.favouriteIcon, favouriteExperts?.includes(listingId) && css.addedFavouriteIcon)} 
                        onClick={handlePreferredInsightGigPartner}
                      >
                        <IconCard brand='favourite' />
                      </span>
                      <button
                        className={classNames(css.compareButton, {
                          [css.disabled]: isExpertSelected,
                        })}
                        onClick={handleSelectExpert}
                        disabled={isExpertSelected}
                      >
                        <FormattedMessage id="ListingPage.compareExpertButton" />
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
            {!isClient && (
              <>
                {(isBrief || isJobListing) ? (
                  <div className={css.sendBriefButtonWrapper}>
                    <Button className={css.sendBrief} onClick={toggleRequestModal}>
                      <FormattedMessage id={isBrief ? "BookingPanel.sendProposal" : 'BookingPanel.applyForJob'} />
                    </Button>
                    <Button className={css.scheduleButton} onClick={() => {
                      setModal(true)
                      sessionStorage.removeItem('params')
                    }}>
                      <FormattedMessage id="BookingPanel.ScheduleMeeting" />
                    </Button>
                  </div>
                ) : (
                  <div className={css.badgeButtonWrapper}>
                    <Button 
                      className={css.sendBrief} 
                      // onClick={handleGeneratePdf}
                      inProgress={fetchResume}
                    >
                      Create PDF resume
                    </Button>
                    <Button className={css.comingSoonBadge}>
                      Coming soon...
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {firmLogoUrl && (
        <img 
          style={{
            width: firmLogoShape === "rectangle" ? "75px" : "50px", 
            borderRadius: firmLogoShape === "circle" ? "50%" : "0"
          }}
          className={css.firmLogo} 
          src={firmLogoUrl} 
        />
      )}

      <Modal
        id="ListingPage.maxExpertModal"
        isOpen={openMaxExpertModal}
        onClose={() => setOpenMaxExpertModal(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.maxExpertWarningModal}>
          <h2 className={css.heading}><FormattedMessage id="ListingPage.maxExpertWarningTitle" /></h2>
          <p className={css.subHeading}><FormattedMessage id="ListingPage.maxExpertWarningDescription" /></p>
          <div className={css.buttonWrapper}>
            <PrimaryButton onClick={() => setOpenMaxExpertModal(false)}>
              Okay
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <Modal
        id="ListingPage.briefShareLimit"
        isOpen={openBriefLimitModal}
        containerClassName={css.maxBriefLimitModal}
        onClose={() => setOpenBriefLimitModal(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.maxExpertWarningModal}>
          <h2 className={css.heading}><FormattedMessage id="ListingPage.briefSharingLimitHeading" /></h2>
          <p className={css.subHeading}><FormattedMessage id="ListingPage.briefSharingLimitHeadingDescription" /></p>
          <div className={css.buttonWrapper}>
            <span className={css.buttonCancel} onClick={() => setOpenBriefLimitModal(false)}>
              Cancel
            </span>
            <PrimaryButton className={css.submitButton} onClick={handleSendAdminEmail}>
              Email InsightGig
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SectionHeading;
