import React from 'react';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { getRefinedLabel } from '../../util/destructorHelpers';
import classNames from 'classnames';

const SectionOptionMaybe = props => {
  const {
    options,
    descriptionCategoriesOptions,
    classNameBlock,
    optionaldescriptionCategoriesOptions,
    classNameElement,
    classNameTitle,
    label,
    domainExpertise,
    industrySector,
    industryUSP = {},
    serviceUSP = {},
    isIndustrySector,
    // optionalServices,
    // listingRole,
    // descriptionOthersIndustries
  } = props;

  const isUspPresent = isIndustrySector ? !!Object.keys(industryUSP)?.length : !!Object.keys(serviceUSP)?.length;

  if (!options || !options.length) return null;
  
  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>{label}</h2>
      {isUspPresent && (
        <div className={css.uspRow}>
          <span className={css.uspHeading}>USP:</span>
          <span 
            className={classNames(css.uspValue, !isIndustrySector ? css.domainExp : css.industryUSP)}
            data-tooltip={isIndustrySector 
              ? "The expert has marked this industry/sector as their USP" 
              : "The expert has marked this service as their USP"
            }
          >
            {isIndustrySector ? industryUSP?.label : serviceUSP?.label}
          </span>
        </div>
      )}
      <ul className={classNameBlock}>
        {options &&
          options.map((el, i) => {
            const refinedLabel = getRefinedLabel(
              el,
              domainExpertise,
              industrySector,
              descriptionCategoriesOptions,
              optionaldescriptionCategoriesOptions
            );
            return (
              <li className={classNameElement} key={i} >
                {el && <label className={classNameTitle}>{refinedLabel}</label>}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SectionOptionMaybe;
