import React from 'react';
import css from "./ListingPage.module.css";
import {calculateQuantityFromTimes, monthYearsString} from "../../util/dates";
import {FormattedMessage} from "../../util/reactIntl";

const SectionInfoEducation = ({ option }) => {

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="EditListingCertificationPanel.certificationLabel" />
      </h2>
      {option && option.map( el => (
        <div key={el.number} className={css.blockInfo}>
          <span className={css.blockTitle}>{el.certification}</span>
          <div className={css.blockCompany}>
            <span>{el.organisation}</span>
          </div>
          <div className={css.blockExperience}>
            {el.notExpire && (el.notExpire.length || !el.endDate) ?
              <span>Issued {monthYearsString(el.startDate)}. Certificate does not expire. </span>
              : <>
                <span>{monthYearsString(el.startDate)} - </span>
                <span>{monthYearsString(el.endDate)} • </span>
                <span>{calculateQuantityFromTimes(el.startDate, el.endDate)}</span>
              </>
            }
          </div>
          <span className={css.blockDescription}>{el.certificationURL}</span>
        </div>
      ))}
    </div>
  );
};

export default SectionInfoEducation;
