import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import css from './ListingPage.module.css';
import { useEffect, useState } from 'react';
import { addTag, handleWeavaiteDatabase, updateListingPublicData } from '../../util/api';
import { Button, IconCard } from '../../components';
import { fetchTagsSuccess, updateUserProfileThroughIntegrationSdk } from './ListingPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { offlineSubscriptionObject, userSubscriptions } from '../../util/destructorHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { updateListingError } from '../EditListingPage/EditListingPage.duck';
import { updateUserDetailsInWeaviate } from '../BecomeInsightGigPartnerPage/BecomeInsightGigPartnerPage.duck';

const MAX_TAG_LIMIT = 30;

const ClientTags = (props) => {
  const { listing, isClient, currentUser, history } = props;
  const listingId = listing && listing?.id;
  const currentUserId = currentUser?.id && currentUser?.id?.uuid;
  const myTags = currentUser?.id && currentUser?.attributes?.profile?.publicData?.myTags || [];
  const offlineSubscriptionData = currentUser?.id && !!offlineSubscriptionObject(currentUser)?.isOfflineSubscriptionPaid;
  const onlineSubscriptionData = currentUser?.id && !!userSubscriptions(currentUser)?.find(sub => sub.plan)?.plan;

  const dispatch = useDispatch();
  const { tags = [] } = useSelector(state => state.ListingPage)
  const [input, setInput] = useState('');
  const [showInput, setShowInput] = useState(false);
  const clientTags = listing?.id && listing?.attributes?.publicData?.clientTags || [];

  const [list, setList] = useState(clientTags);
  const [disabled, setDisabled] = useState(false);
  const [userTags, setUserTags] = useState(myTags);
  const [showError, setShowError] = useState(false);

  //Update expert's tags in weaviate database
  async function updateTagsInWeaviate(tags){
    try{
      await dispatch(updateUserDetailsInWeaviate({
        currentUser, 
        listingId: listing?.id?.uuid,
      }))
    }catch(error){
      console.log(error, 'Error updating tags in weaviate!')
      dispatch(updateListingError(error));
    }
  }

  const addTagToDataBase = () => {
    if (tags?.length <= 20 && !tags?.includes(input.toLowerCase())) {
      addTag(input)
      dispatch(fetchTagsSuccess([...tags, input]))
    }
  }

  const handleUserTags = () => {
    const isTagExisted = userTags?.length && userTags?.find(tag => tag.toLowerCase() === input.toLowerCase())

    !isTagExisted && setUserTags(preProp => {
      const profile = {
        id: currentUser?.id,
        publicData: {
          myTags: userTags?.length ? [...preProp, input] : [input],
        },
      };

      dispatch(updateUserProfileThroughIntegrationSdk(profile))
      return [...preProp, input]
    })
  }

  const handleAddTag = async () => {
    const listItem = input && list?.length &&
      list.find(e => e.label.toLowerCase() === input.toLowerCase())

    input && setList(preProp => {
      if (list?.length && listItem) {
        listItem.count = listItem.count + 1
        listItem.client = [...listItem.client, currentUserId]
        updateListingPublicData({
          id: listingId,
          publicData: {
            clientTags: list
          }
        })

        return list
      }
      else {
        const param = {
          label: input,
          count: 1,
          client: [currentUserId]
        }

        if (userTags?.length <= 10) handleUserTags()

        updateListingPublicData({
          id: listingId,
          publicData: {
            clientTags: [...preProp, param],
            tags: [...preProp, param].map(item => item.label.toLowerCase()),
          }
        })
        updateTagsInWeaviate([...preProp, param].map(item => item.label.toLowerCase()))

        return [...preProp, param]
      }
    })

    setInput('');
    setShowInput(false)
    dispatch(fetchTagsSuccess([...tags, input]))
    addTagToDataBase()
  }

  const handleSelect = (value) => {
    const isItemExist = value && list?.length 
      ? !!list.find(e => e.label.toLowerCase() === value.toLowerCase())
      : false;

    if (isItemExist) return

    setList(preProp => {
      const param = {
        label: value.toLowerCase(),
        count: 1,
        client: [currentUserId]
      }

      updateListingPublicData({
        id: listingId,
        publicData: {
          clientTags: [...preProp, param],
          tags: [...preProp, param].map(item => item.label.toLowerCase()),
        }
      })
      updateTagsInWeaviate([...preProp, param].map(item => item.label.toLowerCase()))
      return [...preProp, param]
    })

    setInput('');
    setShowInput(false)
  }

  const handleToggleTag = async (item, isItemAdded) => {
    try {
      const index = list?.findIndex(e => e.label === item.label);

      if (index === -1) return; // Item not found in the list

      const modifiedList = [...list];
      const listItem = modifiedList[index];

      if (isItemAdded) {
        listItem.count = Math.max(0, listItem.count - 1);
        listItem.client = listItem.client.filter(clientId => clientId !== currentUserId);
        if (listItem.count === 0) modifiedList.splice(index, 1);
      } else {
        listItem.count += 1;
        listItem.client.push(currentUserId);
      }

      const updateData = {
        id: listingId,
        publicData: {
          clientTags: modifiedList,
          tags: modifiedList.map(tag => tag.label),
        },
      };

      await updateListingPublicData(updateData);
      updateTagsInWeaviate(modifiedList.map(tag => tag.label))

      setList(modifiedList);
    } catch (error) {
      return;
    }
  };


  let count = 0;
  useEffect(() => {
    if (list?.length >= 3) {
      list.forEach(item => {
        if (item.client?.includes(currentUserId)) {
          count++
        }
      })
    }
    count === 3 ? setShowError(true) : setShowError(false)
  }, [list])

  useEffect(() => {
    const item = list?.length && list.find(item => item.label === input.toLowerCase())
    item && item.client.includes(currentUserId) ? setDisabled(true) : setDisabled(false);
  }, [input])

  // handleTagSearchProfile
  async function handleTagSearchProfile(tag) {
    try {
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          { pub_allTags: `has_any:${tag}` }
        )
      );
    } catch (error) {
      return;
    }
  }

  const canClientGiveTag = (offlineSubscriptionData || onlineSubscriptionData) && !showError && list?.length !== MAX_TAG_LIMIT;

  return (
    <div>
      {list?.length !== 0 && (
        <ul className={classNames(css.shadowBox, css.engagementRoleElementBlock)}>
          {list?.length !== 0 && list?.map((e, i) => {
            const isItemAdded = e?.client?.includes(currentUserId);

            return (
              <li className={css.tagParentsElement} key={i} tag-count={e.count}>
                <label onClick={() => handleTagSearchProfile(e.label)}>{e.label}</label>
                {isClient &&
                  <span>
                    {isItemAdded ? (
                      <span className={css.closeItem} onClick={() => handleToggleTag(e, isItemAdded)}>
                        <IconCard brand="crossSign" />
                      </span>
                    ) : (
                      <span 
                        className={classNames(!canClientGiveTag && css.pointerEvents)}
                        onClick={() => canClientGiveTag && handleToggleTag(e, isItemAdded)} 
                      >+</span>
                    )}
                  </span>
                }
              </li>
            )
          })}
        </ul>
      )}
      {isClient ?
        <>
          {showInput ?
            <div className={css.inputContainer}>
              <div>
                <input
                  type='text'
                  value={input}
                  onChange={e => setInput(e.target.value)}
                  onKeyPress={e => e.key === 'Enter' && handleAddTag()}
                />
                <ul>
                  {input && tags?.length !== 0 &&
                    tags?.map((item, index) => {
                      if (item.includes(input.toLowerCase())) {
                        return (
                          <li key={index} onClick={() => handleSelect(item)}>
                            {item}
                          </li>
                        )
                      }
                    })}
                </ul>
              </div>
              <Button className={css.addButton} onClick={handleAddTag} disabled={disabled}>
                <FormattedMessage id="ListingPage.addTag" />
              </Button>
              <span className={css.closeTag} onClick={() => setShowInput(false)}>
                <FormattedMessage id="ListingPage.closeTagInput" />
              </span>
            </div> :
            <>
              <button
                className={(showError || list?.length == MAX_TAG_LIMIT) ? classNames(css.addTagButton, css.disabled) : css.addTagButton}
                onClick={() => setShowInput(true)}
                disabled={showError || list?.length == MAX_TAG_LIMIT}
              >
                <FormattedMessage id="ListingPage.addTagLabel" />
              </button>
              {list?.length && list?.length == MAX_TAG_LIMIT ?
                <p className={css.error}>
                  <FormattedMessage id="ListingPage.maxTagsLimitError" values={{ limit: MAX_TAG_LIMIT }} />
                </p> : showError &&
                <p className={css.error}>
                  <FormattedMessage id="ListingPage.maxTagsErrorMessage" values={{ text: <b>three</b> }} />
                </p>
              }
            </>
          }
        </> : null
      }
    </div>
  )
}

export default compose(injectIntl)(ClientTags)