import React from 'react';
import css from "./ListingPage.module.css";
import {calculateQuantityFromTimes, monthYearsString} from "../../util/dates";
import {FormattedMessage} from "../../util/reactIntl";

const sectionInfoExperienceWork = ({ option }) => {

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="EditListingWizard.tabLabelExperience" />
      </h2>
      {option && option.map( el => (
        <div key={el.number} className={css.blockInfo}>
          <span className={css.blockTitle}>{el.job}</span>
          <div className={css.blockCompany}>
            <span>{el.company} • </span>
            <span>{el.typeEmployment}</span>
          </div>
          <div className={css.blockExperience}>
            <span>{monthYearsString(el.startDate)} - </span>
            <span>{el.isWork && el.isWork.length || !el.endDate ? 'Present' : monthYearsString(el.endDate)} • </span>
            <span>{calculateQuantityFromTimes(el.startDate, el.endDate)}</span>
          </div>
          <span className={css.blockDescription}>{el.description}</span>
        </div>
      ))}
    </div>
  );
};

export default sectionInfoExperienceWork;
